import React from 'react'

const Extrude = ({count, offsetFactor = 2}) => {
    const arr = new Array(count).fill(10);
    const offsets = arr.map((e, i) => <ShineOffset offsetFactor={offsetFactor} n={i + 1} />)
    const composites = arr.map((e, i) => i > 2 && <ShineComposite n={i - 1} />)
    return <React.Fragment>
        {offsets}
        <feComposite operator="over" in={`offset2`} in2={`offset1`} result="c1"></feComposite>
        {composites}
        <feComposite operator="out" in={`c${count - 2}`} in2="SourceGraphic" result="shadow"></feComposite>
    </React.Fragment>
    
}


const ShineOffset = ({n, offsetFactor}) => <feOffset dx={`-${n * offsetFactor}`} dy ={`${n * offsetFactor}`} in={`fatter`} result={`offset${n}`} />

const ShineComposite = ({n}) => <feComposite operator="over" in={`c${n-1}`} in2={`offset${n+1}`} result={`c${n}`}></feComposite>



export default Extrude