import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Title = styled.h1`
  color: var(--primeGray);
  padding: 0.5rem 0.5rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
`

const CategoryWrapper = styled.a`
  font-size: 1.5rem;
  color: var(--offPrimeGray);
  margin: 0;
  padding-right: 0.25em;
  font-weight: 400;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  & > span {
    border-bottom: solid 2px;
  }
  &:hover,
  &:focus {
    color: var(--bgGray);
    background-color: var(--offPrimeGray);
    & > span {
      border-bottom-color: transparent;
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row;
  border-bottom: solid 1px var(--offPrimeGray);

  justify-content: stretch;
`
export default ({ children, category }) => (
  <TitleWrapper>
    {category && (
      <CategoryWrapper noopener href={'#'}>
        <span>{category}</span> &rArr;
      </CategoryWrapper>
    )}
    <Title>{children}</Title>
  </TitleWrapper>
)
