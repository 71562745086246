import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import styled, { css } from 'styled-components'

const Variations = styled.div`
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
`

const FancyRadio = styled.div`
  overflow: hidden;
  padding: 0.2em;
  text-align: center;
  width: 4em;
  cursor: pointer;
  color: var(--primeColor);
  height: 100%;
  &.checked {
    background: var(--primeColor);
    color: var(--bgColor);
  }

  input {
    position: absolute;
    left: -100%;
    cursor: pointer;
  }

  label {
    font-weight: bold;
    cursor: pointer;
  }
`

export default () => (
  <ThemeToggler key="themeToggler">
    {({ theme, toggleTheme }) => (
      <Variations className="">
        {['light', 'dark'].map((v, i) => (
          <FancyRadio
            key={v}
            className={` relative ${v === theme ? 'checked' : ''}`}
          >
            <label htmlFor={v}>{v}</label>
            <input
              type="radio"
              className=""
              id={v}
              onChange={() => toggleTheme(v)}
              name="variation"
              value={v}
              checked={v === theme}
            />
          </FancyRadio>
        ))}
      </Variations>
    )}
  </ThemeToggler>
)
