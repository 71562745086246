import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Extrude from './Extrude'



const SVGWrapper = styled.svg`
height: 4em;
margin-top: -1em;
@media screen and (max-width: 30em) {
    height: 2.2em;
    width: ${({svgWidth}) => svgWidth ? `${svgWidth}rem` : 'initial'};

}
&:not(:root) {
overflow: visible;
}
`

const Transformed = styled.g`
transition: all ease 0.5s;
transform-origin: -20% 10%;

&:not(.active) path {
        fill: var(--primeColor);
        transition: all ease 0.5s;
        transform: scale(2) translateX(-61%) translateY(20%);
    }

`

const LinkWrapper = styled(Link)`
text-decoration: none;
position: relative;
    display: block;
    transiton: all 0.2s ease;
    transform: none;
    
${Transformed} {
    transform: ${(props) => props.isActive ? ' translateX(1em) translateY(-1em) var(--perspectiveTransform);' : 'var(--perspectiveTransform)'} ;
    @media screen and (max-width: 30em) {
    transform: ${(props) => props.isActive ? 'var(--perspectiveTransform);' : 'none'} ;;
}
}
&:hover, &:focus {
    --primeColor: var(--primeGray);
    
    ${Transformed} {
    transform: ${(props) => props.isActive ? 'var(--perspectiveTransform) ' : 'none'} ;
    @media screen and (max-width: 30em) {
        
        transform: ${(props) => props.isActive ? 'var(--perspectiveTransform) translateY(-0.75em)' : 'translateY(-0.75em)'};
    }
    & path {
        transform: ${(props) => props.isActive ? '' : 'scale(2) translateX(-61%) translateY(-94%);'};

    }
}
@media screen and (max-width: 30em) {
    border-bottom: solid 1px var(--primeColor);
    }

}
@media screen and (max-width: 30em) {
    border-bottom: solid 1px transparent;
    }
`


const ShineText = styled.text`
text-transform: uppercase;
text-anchor: end;
font-size: 4em;
font-weight: 900;
fill: var(--primeColor);
filter: ${props => props.hasFilter ? `url(#${props.slug}-outline)` : `url(#${props.slug}-fat)`};
@media screen and (max-width: 30em) {
    height: 15vw;
    filter: ${props => props.isActive ? `url(#${props.slug}-outline)` : 'none'};
}
`


export const ShineLink = ({children, slug, path, svgWidth = false}) => <LinkWrapper
to={`/${slug}`}
activeClassName="active"
isActive={path && path.indexOf(slug) > 0}
>
<Shine isActive={path && path.indexOf(slug) > 0}
slug={slug}
path={path}
svgWidth={svgWidth}
>
{children}
</Shine>
</LinkWrapper>

const Shine = ({children, slug, isActive = false, svgWidth = false}) => {
  return (
    <SVGWrapper
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-195 -105 190 120"
    svgWidth={svgWidth}
    >
        <filter id={ `${slug}-fat` } width="140%" x="-20%" height="160%" y="-20%">
        <feMorphology operator="dilate" radius="3" in="SourceGraphic" />
        </filter>
        <filter id={ `${slug}-outline` } width="140%" x="-30%" height="160%" y="-20%">
            <feMorphology operator="dilate" radius="3" in="SourceGraphic" result={`fat`} />
            <feMorphology operator="dilate" radius="3" in="SourceGraphic" result={`fatter`} />
            {  isActive && <Extrude count={15} /> }
            <feComposite operator="out" in={`fat`} in2="SourceGraphic" result="prime"></feComposite>
            <feMerge>
                <feMergeNode in="prime" />
                { isActive && <feMergeNode in="shadow" /> }
            </feMerge>
        </filter>
        <Transformed>
            <clipPath id={`${slug}-textClip`}>
                <ShineText slug={slug} className=""  >
                    { children }
                </ShineText>
            </clipPath>
                <ShineText hasFilter isActive={isActive} slug={slug} className="" >
                    { children }
                </ShineText>
            <g clipPath={`url(#${slug}-textClip)`}>
                <path  d="M117,64.5v0.6L0,0.6V0L117,64.5z M0,22.4l117,64.5v-1.1L0,21.3V22.4z M0,19.2l117,64.5v-1.1L0,18.1V19.2z
                M0,44.9l117,64.5V93.4L0,28.9V44.9z M0,7.8l117,64.5V68L0,3.5V7.8z M0,150.6l117,1.9V116L0,51.6V150.6z"/>
            </g>
        </Transformed>
    </SVGWrapper>
  )
}

export default Shine
