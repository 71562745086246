import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import {GithubLink, InstagramLink} from './SocialLinks'

const BioWrapper = styled.div`
  text-align: right;
  padding-right: 3em;
  pointer-events: auto;
  transform: var(--perspectiveTransform);
  margin-top: 0.5em;
  display: flex;
    flex-direction: column;
    align-items: flex-end;
  & > .bio__p {
    margin-top: 0;
    line-height: 1;
    max-width: 9em;
    color: var(--primeColor);
  }
  @media screen and (max-width: 30em) {
    margin-top: 0;
    transform: none;
    background-color: var(--bgColor);
    border-bottom: solid 1px var(--primeColor);
    & > .bio__p {
    margin-bottom: 0.75rem;
    display: none;
    }
    & > .bio__p:last-of-type {
    display: none;
    }
  }
  @media screen and (max-width: 20em) {
    padding-top: 3em;
  }
`
const Socials = styled.div`
& > a {
  padding: 0.5em;
}
@media screen and (max-width: 20em) {
display: none;
}
`

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social, tagline } = data.site.siteMetadata
        return (
          <BioWrapper>
            <p className="bio__p">
              {tagline}
            </p>
            <p className="bio__p">Raleigh, N.C.</p>
            <Socials>
            <InstagramLink user={social.instagram} />
            <GithubLink user={social.instagram} />
            </Socials>
            
          </BioWrapper>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/miles_elliott.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        tagline
        social {
          twitter
          instagram
        }
      }
    }
  }
`

export default Bio
