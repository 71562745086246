import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

const StyledLink = styled.a`
display: inline-block;
  .social__svg {
    fill: var(--primeColor);
    height: 2em;
    overflow: visible;
    &--big {
      height: 3em;
    }
  }
  &:hover, &:focus {
    .social__svg {
      fill: var(--primeGray);
    }
  }
`


const SocialLink = ({href, children}) => (
  <StyledLink
    target="_blank"
    noopener
    href={href}
  >
    {children}
  </StyledLink>
)

const GithubLink = ({user, isBig = false}) =>
<SocialLink href={`https://github.com/${user}`}>
<svg className={`social__svg ${isBig ? 'social__svg--big' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <title>GitHub Profile</title>
  <path d="M16.94.44a16.291,16.291,0,0,0-5.148,31.747c.815.149,1.112-.353,1.112-.785,0-.387-.014-1.411-.022-2.771C8.351,29.616,7.4,26.448,7.4,26.448a4.315,4.315,0,0,0-1.809-2.383c-1.479-1.011.112-.99.112-.99a3.42,3.42,0,0,1,2.495,1.678,3.468,3.468,0,0,0,4.741,1.354,3.477,3.477,0,0,1,1.034-2.178c-3.617-.411-7.42-1.808-7.42-8.051a6.3,6.3,0,0,1,1.677-4.371A5.855,5.855,0,0,1,8.384,7.2s1.368-.438,4.48,1.67a15.447,15.447,0,0,1,8.156,0C24.13,6.758,25.5,7.2,25.5,7.2a5.846,5.846,0,0,1,.162,4.311,6.287,6.287,0,0,1,1.675,4.371c0,6.258-3.809,7.635-7.438,8.038A3.889,3.889,0,0,1,21,26.933c0,2.178-.02,3.935-.02,4.469,0,.435.293.942,1.12.783A16.292,16.292,0,0,0,16.94.44Z"/>
</svg>
</SocialLink>

const InstagramLink = ({user, isBig = false}) =>
<SocialLink href={`https://instagram.com/${user}`}>
<svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={`social__svg ${isBig ? 'social__svg--big' : ''}`}
    >
      <g>
        <path
          d="M256,49.47c67.27,0,75.23,0.26,101.8,1.47c24.56,1.12,37.9,5.22,46.78,8.67c11.76,4.57,20.15,10.03,28.97,18.84
		c8.82,8.82,14.28,17.21,18.84,28.97c3.45,8.88,7.55,22.22,8.67,46.78c1.21,26.56,1.47,34.53,1.47,101.8s-0.26,75.23-1.47,101.8
		c-1.12,24.56-5.22,37.9-8.67,46.78c-4.57,11.76-10.03,20.15-18.84,28.97c-8.82,8.82-17.21,14.28-28.97,18.84
		c-8.88,3.45-22.22,7.55-46.78,8.67c-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-0.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67
		c-11.76-4.57-20.15-10.03-28.97-18.84c-8.82-8.82-14.28-17.21-18.84-28.97c-3.45-8.88-7.55-22.22-8.67-46.78
		c-1.21-26.56-1.47-34.53-1.47-101.8s0.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78c4.57-11.76,10.03-20.15,18.84-28.97
		c8.82-8.82,17.21-14.28,28.97-18.84c8.88-3.45,22.22-7.55,46.78-8.67C180.77,49.73,188.73,49.47,256,49.47 M256,4.08
		c-68.42,0-77,0.29-103.87,1.52c-26.81,1.22-45.13,5.48-61.15,11.71c-16.57,6.44-30.62,15.05-44.62,29.06
		C32.36,60.37,23.74,74.42,17.3,90.98c-6.23,16.02-10.49,34.34-11.71,61.15C4.37,179,4.08,187.58,4.08,256
		c0,68.42,0.29,77,1.52,103.87c1.22,26.81,5.48,45.13,11.71,61.15c6.44,16.57,15.05,30.62,29.06,44.62
		c14.01,14.01,28.05,22.62,44.62,29.06c16.02,6.23,34.34,10.49,61.15,11.71c26.87,1.23,35.45,1.52,103.87,1.52s77-0.29,103.87-1.52
		c26.81-1.22,45.13-5.48,61.15-11.71c16.57-6.44,30.62-15.05,44.62-29.06c14.01-14.01,22.62-28.05,29.06-44.62
		c6.23-16.02,10.49-34.34,11.71-61.15c1.23-26.87,1.52-35.45,1.52-103.87s-0.29-77-1.52-103.87c-1.22-26.81-5.48-45.13-11.71-61.15
		c-6.44-16.57-15.05-30.62-29.06-44.62c-14.01-14.01-28.05-22.62-44.62-29.06c-16.02-6.23-34.34-10.49-61.15-11.71
		C333,4.37,324.42,4.08,256,4.08L256,4.08z"
        />
        <path
          d="M256,126.64c-71.45,0-129.36,57.92-129.36,129.36S184.55,385.36,256,385.36S385.36,327.45,385.36,256
		S327.45,126.64,256,126.64z M256,339.97c-46.38,0-83.97-37.6-83.97-83.97s37.6-83.97,83.97-83.97c46.38,0,83.97,37.6,83.97,83.97
		S302.38,339.97,256,339.97z"
        />
        <circle cx="390.48" cy="121.52" r="30.23" />
      </g>
    </svg>
</SocialLink>

export {GithubLink, InstagramLink}