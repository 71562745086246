import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import ThemeSwitcher from './ThemeSwitcher'
import {ShineLink} from './ShineText'
const Wrapper = styled.header`
  border-right: solid 1px var(--primeColor);
  position: fixed;
  background-color: var(--bgColor);
  height: 100vh;
  padding-left: 0rem;
  
  @media screen and (min-width: 30rem) {
    padding-left: 0;
  }
  @media screen and (min-width: 60rem) {
    padding-left: 4rem;
  }
  @media screen and (max-width: 30em) {
    border-right: none;
    width: 100%;
    z-index: 20;
    background-color: transparent;
    pointer-events: none;
  }
`

const Nav = styled.nav`
  padding-top: 5rem;
  width: 12rem;
  background-color: var(--bgColor);
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: 30em) {
    text-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0.25rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: var(--bgColor);
    border-top: solid 1px var(--primeColor);
  }
`
const NavLinks = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  pointer-events: auto;
  margin-right: 1rem;
  @media screen and (max-width: 30em) {
    display: flex;
    flex-fow: row wrap;
    justify-content: space-around;
    width: 100%;
    padding-left: 1rem;;
    padding-right: 1rem;
    margin-right: 0;
    & > li {
      display: inline-block;
      flex-basis: 25%;
    }
  }
`


const SiteTitle = styled(Link)`
  margin: 0;
  pointer-events: auto;
  color: var(--primeColor);
  transform: var(--perspectiveTransform);
  position: absolute;
  text-align: right;
  padding-top: 1rem;
  padding-right: 2rem;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  &:visited {
    color: var(--primeColor);
  }
  &:hover, &:focus {
    --primeColor: var(--primeGray)
  }
  @media screen and (max-width: 30em) {
    transform: none;
    padding-left: 1rem;
    font-size: 1.5rem;
    padding-top: 0.75rem;
  }
  @media screen and (max-width: 18em) {
    font-size: 10vw;
  }
`

const links = [
  { name: 'Blog', slug: 'blog' },
  { name: 'About', slug: 'about' },
]


function Header({children, path, isRoot }) {
  return (
    <StaticQuery
      query={headerQuery}
      render={data => {
        const { author, social, title, tagline } = data.site.siteMetadata
        return (
        <Wrapper key="header" className="header">
      {!isRoot && <SiteTitle to={'/'} className="title">
        <span>{title}</span>
      </SiteTitle>}
      <Nav className="shard">
        <NavLinks>
          {links.map((l, i) => (
            <li key={l.slug}>
              
                <ShineLink svgWidth={l.slug==='about' ? 5 : false} path={path} slug={l.slug}>
                  {l.name}
                </ShineLink>
              
            </li>
          ))}
        </NavLinks>
      </Nav>
      <div>

      {children}
      </div>
    </Wrapper>
        )
      }}
    />
  )
}

const headerQuery = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title,
        tagline,
        author
        social {
          twitter
          instagram
        }
      }
    }
  }
`

export default Header
