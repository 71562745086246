import React from 'react'
import { Link } from 'gatsby'
import '../styles/base.scss'
import Header from './Header'
import Title from './Title'
import Bio from './Bio'
import styled from 'styled-components'

const Page = styled.div`
  align-items: stretch;
  --perspectiveTransform: rotateX(25deg) rotateY(25deg);
  --activeTransform: translateX(0.5em) translateY(-0.5em);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: calc(100% - 12rem);
  padding-bottom: 3rem;

  padding-top: 0;
    margin-left: 12rem;
    
    --pad6: 15vw;
    --pad5: 12.5vw;
    --pad4: 10vw;
    --pad2: 5vw;
    --pad1: 2.5vw;

  
  @media screen and (max-width: 30rem) {
    max-width: unset;
    margin-left: 0;
  padding-top: 3rem;
  
  --pad6: 1.5rem;
  --pad5: 1.25rem;
  --pad4: 1rem;
  --pad2: 0.5rem;
  --pad1: 0.25rem;
  }
  @media screen and (min-width: 60rem) {
    margin-left: 16rem;
    --pad6: 9rem;
    --pad5: 7.5rem;
    --pad4: 6rem;
    --pad2: 3rem;
    --pad1: 1.5rem;
  }
`

class Layout extends React.Component {
  render() {
    const { location, title, children, className = '' } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const isRoot = location.pathname === "/";
    return (
      <Page className={`flex sans-serif flex-column flex-row-ns ${className}`}>
        <Header path={location.pathname} isRoot={isRoot} title={title}>
          {!isRoot && <Bio />}
        </Header>
        <Content>{children}</Content>
      </Page>
    )
  }
}

export default Layout
